import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Gallery, {SandahlAlbum} from './Gallery';
import About from './About';
import Contact from './Contact';
import SandahlMenu from './Menu';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
                <div>
                    <SandahlMenu
                        First={<App />}
                        firstLabel="Home"
                        Second={<Gallery />}
                        secondLabel="Gallery"
                        Third={<Contact />}
                        thirdLabel="Contact"
                        Fourth={<About />}
                        fourthLabel="About"
                        Fifth={<SandahlAlbum />}
                        fifthLabel="Portfolio"
                    />
                </div>
                , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
