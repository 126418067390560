export const photos = [
  {
    src: 'https://thumbnails-photos.amazon.com/v1/thumbnail/eQ-4Cl2fSeyhIROR_3gZ-Q?viewBox=958%2C639&ownerId=A1N25368HH528H&groupShareToken=X5KipiQcRQy6yT2vRYHzTg.jBwNbLJWqsSiyTDmKn7-iv',
    width: 3,   
    height: 2
  },
  {
    src: 'https://thumbnails-photos.amazon.com/v1/thumbnail/fh4OAa9bQGGdwqCKTcLihg?viewBox=1414%2C943&ownerId=A1N25368HH528H&groupShareToken=Ik5_wLCITAq-dW1nMBshVA.O12_kEbvyr0AbUYTO5RAC4',
    width: 3,   
    height: 2
  },
  {
    src: 'https://thumbnails-photos.amazon.com/v1/thumbnail/LJ0Ac6PMS6SCc4HDI8WcpA?viewBox=936%2C936&ownerId=A1N25368HH528H&groupShareToken=nyKVmG5uR7qMzNYvdINAmQ.BarBnD5cQe6WOGikVeg24a',
    width: 3,
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/60788042_2080542682069035_833308416603586560_o.jpg?_nc_cat=105&_nc_oc=AQl_NMf4b-vKG2a6Wur5_hmPS0hO4P61bUQ0BkI_--zRsyHRsUxUG4qzgo6ewUvLFy320vhXD9HztnE64QlLG-LX&_nc_ht=scontent-ort2-1.xx&oh=dc1128979f5a8f82feb0c1d9757cc01f&oe=5D8F507F',
    width: 3,   
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/60710197_2080542702069033_2947195778147287040_o.jpg?_nc_cat=104&_nc_oc=AQmNpxzKZ6Za7WYRuRxkm10yet5vCPcKC6snw5AoBi8mEzdSF5kIfpXtVr6gGWQuDXp0kFvrUokKbMlWv9eJNWlE&_nc_ht=scontent-ort2-1.xx&oh=23914370c0fcbe422583cf5d214141c2&oe=5D98AC54',
    width: 3,   
    height: 2
  },
  {
    src:'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/60793158_2080542692069034_7032986024959541248_o.jpg?_nc_cat=108&_nc_oc=AQmQWwTMFnuTKF_v4DOKkn2-9I6yzZAhE9q9bOX7K0gW1ZCLu3rgQkDbZr6jx3C1ZfL1ts9u72lW_p_eX_d_N8FU&_nc_ht=scontent-ort2-1.xx&oh=975a072890c14282ace7313251f9e1e4&oe=5D9D29EC',
    width: 3,   
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/60622350_2080542818735688_1620576136708751360_o.jpg?_nc_cat=110&_nc_oc=AQmqVJlnlcaMXNfHF4FqH3W7fp_h2V8UUe-mWHzr8FrYjq2BeYqgwGRN135PoIqACHsnIzSzGZOeucbbFzHA7HqK&_nc_ht=scontent-ort2-1.xx&oh=2e20fbf5eb767b5c5e5bff54ecfefbaf&oe=5D890B3C',
    width: 3,   
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/60911093_2080542868735683_8976519259668611072_o.jpg?_nc_cat=108&_nc_oc=AQlCwVOHqYePCc90_oPd70KKmzN2J27AnjZuPc0XYh2-uNXobD20yZb_nMA2vqmHi9-SBrF8WN-Y3ST-B6BgZM8K&_nc_ht=scontent-ort2-1.xx&oh=dacac2be2026ab76151fd61adaeeec96&oe=5D884FA3',
    width: 3,   
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/61159822_2080542892069014_7122229668639408128_o.jpg?_nc_cat=108&_nc_oc=AQmqvBDvXCgKs7Bn9VP795JprPGTJLRcAftmWPWWBwYZAy8sYVxYwbCaeyPp5FdWboKsZB9FT0cxxfXYVWbLjtpS&_nc_ht=scontent-ort2-1.xx&oh=77284333dff2c38713a4acd001a6b2c1&oe=5D921A28',
    width: 3,   
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/60719616_2080542875402349_6134668725665660928_o.jpg?_nc_cat=105&_nc_oc=AQn5nLvOoVg9JiIOqGmkRxSlmV3alBKp7ypjrzpm6LYe4woitmP9pCIOpvkO5-vD03jrvmhkKi9XKpMk7-DCBj1q&_nc_ht=scontent-ort2-1.xx&oh=4dfae169ef0301cbc7b4d2193fc67669&oe=5D97C09E',
    width: 3,   
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/60624734_2080543058735664_4497834664567767040_o.jpg?_nc_cat=100&_nc_oc=AQnAV99gg6NGRFo8kJISF6nuX-8G8bwVH_3i0Sjn_vCTQhNYYHznHUCpdupBhWsimP9kYV3-f31RRKgAxSuTj53Q&_nc_ht=scontent-ort2-1.xx&oh=9a47623f0bec8366437e828ae9e77355&oe=5D9BD390',
    width: 3,   
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/60480467_2080543082068995_4651737202642911232_o.jpg?_nc_cat=111&_nc_oc=AQkAubK5pGC0X2ZX71QY3vmdzIZlydFKWQH1DraJLWeNg60vIC8zH63F08smN_PLo-l3B8-WFGXAlN8vwuVOMVQz&_nc_ht=scontent-ort2-1.xx&oh=f2b740c5859208947199623a07b3147e&oe=5D9ADD87',
    width: 3,   
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/61091093_2080543098735660_3610334296243437568_o.jpg?_nc_cat=110&_nc_oc=AQm8PX5J8ToXLdTGy5GtvQFlZqo6QxJmyc0hjiJdsjMo_mcyoignhw4Oo28ob7GwYxOEYMV7v9Er0Bj6TStUMwea&_nc_ht=scontent-ort2-1.xx&oh=51c67046ad09188b20d22db49fb101b5&oe=5D82205A',
    width: 3,   
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/61218052_2080543122068991_32872850754895872_o.jpg?_nc_cat=108&_nc_oc=AQmvb05FTxeO4lSZptyX-nRiVprY9bSsvU_7Uf8p5Ff8-1wpdVmSJ2xyCmsiMI5OC5VZphjAIyI0nXO4OUdo40BH&_nc_ht=scontent-ort2-1.xx&oh=005618236629eceb6766e33bf36b7a84&oe=5D7FB801',
    width: 3,   
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/52117078_1945240085599296_1139776125837246464_n.jpg?_nc_cat=100&_nc_oc=AQmpETGXfItJ9XJbG2WIdSokuGOKhrk6ItYwO-VH4dHWhrdgsgivsybJYy9aITpFuyuV0CJbCuOpWgDByOUmzmz0&_nc_ht=scontent-ort2-1.xx&oh=a860c84f0945052e515bae157cd00d5e&oe=5D7BCB28',
    width: 3,   
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/52695014_1945240115599293_8019318731840684032_n.jpg?_nc_cat=101&_nc_oc=AQlONb4h8NBpHnbR2R0U6VVEEezrpPzmYGI3y_TxK6r8qw9Q6tAMtJNNepYXwwgQq-aQr_cBDQhpdkfju9U6Qgov&_nc_ht=scontent-ort2-1.xx&oh=9438c43cb5d7643d4e8db50240954b62&oe=5D984EC1',
    width: 3,   
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/52544862_1945240142265957_2223935856870162432_n.jpg?_nc_cat=109&_nc_oc=AQlEaKk_tRMEZQwJQm249f8fr2MTNppR0yhjnJWPLBSrfdk3mPjIcpnl0nAjQ8Y3DsQ_zLUvyT8_GBgAsOQbnvub&_nc_ht=scontent-ort2-1.xx&oh=ef6196f2a3649ff1a03f393912482e1f&oe=5D8EB42A',
    width: 3,   
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/52387538_1945240162265955_5078954417005264896_n.jpg?_nc_cat=106&_nc_oc=AQlVrblGJZCnWr2cW_peIgegC2KK-79eZS4MAcSqEkSLB2Q6-qipdjGqwdgUYi7kcdlz7rW9GyJ0fiefbiEo9c_2&_nc_ht=scontent-ort2-1.xx&oh=180527713b50717f7695e643a667da32&oe=5D8C3EA9',
    width: 3,   
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/52402690_1945240205599284_6769332010021289984_n.jpg?_nc_cat=103&_nc_oc=AQlBRXaAMZ4bZCMTnHLlR7cUQP2kc5x3MUg9xoIIh7UY0uITxeBq7hTpSjwOOKhuh6Lhvbm4sDOks_0ORPvKDTtX&_nc_ht=scontent-ort2-1.xx&oh=9af91162811d31952f32ee14dddfe58f&oe=5D89DB5F',
    width: 3,   
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/52874778_1945240282265943_9130802958911406080_n.jpg?_nc_cat=109&_nc_oc=AQlLos2VuQP1WrubuFE67upzAVLx640_Xqfe5wSM55USoABz79WJoW6XjtsjGgH5XCygTbZQ_B20gTPrjorckyWf&_nc_ht=scontent-ort2-1.xx&oh=6dd4322290731fab27cb344e4c7ff62c&oe=5D839D53',
    width: 3,   
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/51491141_1929843670472271_2929553388230148096_n.jpg?_nc_cat=106&_nc_oc=AQncRMintz1t4l_uGJvMg7O8KvewJTWKm5X6DrvVuDPfnCzio84g2gIbLYggFa2K8tZWl9InZvX0KMWX5jlmE3JQ&_nc_ht=scontent-ort2-1.xx&oh=21e4924c94476620c6df1837baa33e79&oe=5D888249',
    width: 3,   
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/51378907_1929843687138936_2161328058466828288_n.jpg?_nc_cat=107&_nc_oc=AQlt3sYPZtMerPuyrXOBiIBREHEtCHnkqGRoV3cZ27dWdigxX-rAGxxjD8L5KyJ8lW6oaRfZKI0Lv7LYVJuMB_Q5&_nc_ht=scontent-ort2-1.xx&oh=70d475bbdb7b73e89808f55c7177f097&oe=5D815D03',
    width: 3,   
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/51403771_1929843710472267_5735816759521312768_n.jpg?_nc_cat=107&_nc_oc=AQl5CgRXgOg6uvHuEfctWiTkE97xxcsas8CDTu1ZWTv6U-NqN6LYFrsuVrqkjWaQ6KduDasljWBinjmHEvs9oA7X&_nc_ht=scontent-ort2-1.xx&oh=bfe2058a2527efcda8b7a1b12ce5c5d3&oe=5D8EE984',
    width: 3,   
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/51576924_1929843800472258_5038195516668641280_n.jpg?_nc_cat=108&_nc_oc=AQlhkpfJ2Z903A0ZPrXt5vAvLm0gH58j5Vf1XTRLqpDZYfyeaesyOj4GOEgu7SOvKmrjPVQDDvDmevwS_NZXNWVn&_nc_ht=scontent-ort2-1.xx&oh=54c416b7ef1d014db04185c5593eb078&oe=5D962307',
    width: 3,   
    height: 2
  },
  {
    src: 'https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/51882825_1929843823805589_3271952420199989248_n.jpg?_nc_cat=106&_nc_oc=AQlbrpfSvYBLioWyg0hZ2N8_ZpfMymYps2TqWbjSUjII6PTWh5WH7xcLMYMae_UdrpGnHq8eZH8ccPNeN3YtDBpX&_nc_ht=scontent-ort2-1.xx&oh=7a8fd83f8e3b85d82a68ba2f81de25d5&oe=5D85CBCF',
    width: 3,   
    height: 2
  },
  
];
export const albumTree = [
  { 
    title: 'Bamboo Birdfish',
    description: 'Bordering on churchwarden dimensions, this variation on a blowfish has lines inspired by bird wings. The smooth grain is rare for the 50+ year old Algerian briar I have. Black bamboo nodes are adorned with silver droplets, each one hand-fitted to the node. My signature Sandahl Plywood inlay on the one end, and a small ebony inlay on the other end of the bamboo.',
    weight: '2.6 oz',
    height: '2 7/8"',
    length: '8"',
    chamber: '.73"',
    depth: '1.3"',
    photos: [
      {
        src: '/images/01/01.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/01/02.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/01/03.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/01/04.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/01/05.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/01/06.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/01/07.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/01/08.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/01/09.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/01/10.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/01/11.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/01/12.jpg',
        width: 3,   
        height: 2
      },
    ]
  }, {
    title: "Blowfish",
    description: "A blowfish of chubby proportion cut from the same block and same inlay material as the freehand Dublin, commissioned for brothers to enjoy as a set.",
    photos: [
      {
        src: '/images/02/01.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/02/02.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/02/03.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/02/04.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/02/05.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/02/06.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/02/07.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/02/08.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/02/09.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/02/10.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/02/11.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/02/12.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/02/13.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/02/14.jpg',
        width: 3,   
        height: 2
      },
    ]
  },{
    title: "Dublin Sitter with Brass",
    description: "A commissioned piece, featuring a fantastic blast and a band made of brass. The stem is German Cumberland.",
    photos: [
      {
        src: '/images/03/01.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/02.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/03.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/04.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/05.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/06.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/07.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/08.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/09.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/10.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/11.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/12.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/13.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/14.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/15.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/16.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/17.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/18.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/19.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/20.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/21.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/22.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/23.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/24.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/25.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/26.jpg',
        width: 3,   
        height: 2
      },{
        src: '/images/03/27jpg',
        width: 3,   
        height: 2
      },
    ]
  }
];