import React from 'react';
import './App.css';
import Grid from '@material-ui/core/Grid';

function App() {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <h1>Jeremiah Sandahl Pipes</h1>
        
        started in 2011 when I discovered the relaxation of smoking a pipe could be surpassed only by satisfying my urge to create. Although briar and ebonite were a new medium of expression, I had known and appreciated the beauty of expression through Classical music, especially Bach, Chopin and Liszt and their concert pieces, which I enjoyed playing through my college years. I began my pursuit of creating high grade pipes after the 2013 Chicagoland Pipe Show. It was at that show that I saw a path to success. I was to create a billiard with its unbending simplicity, and a blowfish to begin a free expression of the grain while maintaining the principles of line and flow. These two shapes make up the foundation of my creation. My workshop is located on a beautiful piece of property 3 miles outside of Zimmerman, Minnesota. I can step outside my workshop and see all sorts of wildlife from deer and turkeys to sandhill cranes and bald eagles.
        
        <img src="https://scontent-ort2-1.xx.fbcdn.net/v/t1.0-9/51378907_1929843687138936_2161328058466828288_n.jpg?_nc_cat=107&_nc_oc=AQlt3sYPZtMerPuyrXOBiIBREHEtCHnkqGRoV3cZ27dWdigxX-rAGxxjD8L5KyJ8lW6oaRfZKI0Lv7LYVJuMB_Q5&_nc_ht=scontent-ort2-1.xx&oh=70d475bbdb7b73e89808f55c7177f097&oe=5D815D03" width="100%" alt="logo" />
      </Grid>
    </Grid>
  );
}

export default App;
