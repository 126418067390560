import React from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos, albumTree } from './photos';
import AlbumGallery from './AlbumGallery';
import Grid from '@material-ui/core/Grid';
// http://neptunian.github.io/react-photo-gallery/

export class SandahlAlbum extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewerIsOpen: false,
            hidden: [true, true, true],
        };
    }
    openLightbox = (rowIndex) => {
        if (this.state.hidden[rowIndex] !== false) {
            var list = this.state.hidden;
            list[rowIndex] = false;
            this.setState({ hidden: list });
        }
    };
    closeLightbox = (rowIndex) => {
        if (this.state.hidden[rowIndex] !== true) {
            var list = this.state.hidden;
            list[rowIndex] = true;
            this.setState({ hidden: list });
        }
    };
    toggleGallery = (rowIndex) => {
        //this.openLightbox(rowIndex);
        if (this.state.hidden[rowIndex] !== false) {
            var list = this.state.hidden;
            list[rowIndex] = false;
            this.setState({ hidden: list });
        }
    };
    render() {
        return(
            <Grid container spacing={3}>
            {albumTree.map((album, row) => (
                <Grid item xs={6}>
                    <h2>{album.title}</h2>
                    <p>{album.description}</p>
                    {/* TODO: Add click event to title to toggle specs */}
                    <img src={album.photos[0].src} width="100%" onClick={(event) => this.toggleGallery(row)}/>
                    <AlbumGallery
                        photos={album.photos}
                        hidden={this.state.hidden[row]}
                        rowIndex={row}
                        closeLightbox={this.closeLightbox.bind(this)}
                        openLightbox={this.openLightbox.bind(this)} />
                </Grid>
            ))}
            </Grid>
        )
    }
}

export default class SandahlGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentImage: 0,
            viewerIsOpen: false,
        };
    }
    openLightbox = (event, obj) => {
        this.setState({
            currentImage: obj.index,
            viewerIsOpen: true,
        });
    };
    closeLightbox = () => {
        this.setState({
            currentImage: 0,
            viewerIsOpen: false,
        });
    };
    
    render() {
        const { currentImage, viewerIsOpen, } = this.state;
        return(
            <div>
                <Gallery photos={photos} onClick={this.openLightbox} />
                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={this.closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={photos.map(x => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title
                            }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        )
    }
}