import React from 'react';
import './App.css';
import Grid from '@material-ui/core/Grid';

function App() {
  return (
    <Grid container spacing={4}>
        <Grid item xs={6}>
          <h1>Jeremiah Sandahl Pipes</h1>
          <p>
          started in 2011 when I discovered the relaxation of smoking a pipe could be surpassed only by satisfying my urge to create. Although briar and ebonite were a new medium of expression, I had known and appreciated the beauty of expression through Classical music, especially Bach, Chopin and Liszt and their concert pieces, which I enjoyed playing through my college years. I began my pursuit of creating high grade pipes after the 2013 Chicagoland Pipe Show. It was at that show that I saw a path to success. I was to create a billiard with its unbending simplicity, and a blowfish to begin a free expression of the grain while maintaining the principles of line and flow. These two shapes make up the foundation of my creation. My workshop is located on a beautiful piece of property 3 miles outside of Zimmerman, Minnesota. I can step outside my workshop and see all sorts of wildlife from deer and turkeys to sandhill cranes and bald eagles.</p>
      </Grid>
      <Grid item xs={6}>
        <h1>Each Sandahl Pipe</h1>
        
        is crafted by hand from the selection of high grade, aged briar and German ebonite rod stock to the design, shaping, and polishing process. Pipes crafted as of 2017 are stamped with Jeremiah Sandahl USA and the year. The rarest of grain and exceptional designs are stamped with a sandhill crane. The sandhill crane is currently my only grade and is reserved only for the very best of Sandahl Pipes.
        <br /><br />
        {/* Portfolio */}
        Thank you for taking interest in my work. You can follow my current progress on my
        <a href="https://instagram.com/sandahlpipe">Instagram</a>
      </Grid>
    </Grid>
  );
}

export default App;
