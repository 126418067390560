import React from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from "react-images";

export default class AlbumGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentImage: 0,
            viewerIsOpen: true,
        };
    };
    openLightbox = (event, obj) => {
        this.setState({
            currentImage: obj.index,
            viewerIsOpen: true,
        });
        this.props.openLightbox(this.props.rowIndex);
    };
    closeLightbox = () => {
        this.setState({
            currentImage: 0,
            viewerIsOpen: false,
        });
        this.props.closeLightbox(this.props.rowIndex);
    };
    render() {
        const { currentImage, viewerIsOpen, } = this.state;
        const { photos, hidden=true, closeLightbox, } = this.props;
        return(
            ( hidden ? <></> :
                <div>
                    <Gallery photos={photos} onClick={this.openLightbox} />
                    <ModalGateway>
                        {viewerIsOpen ? (
                            <Modal onClose={this.closeLightbox}>
                                <Carousel
                                    currentIndex={currentImage}
                                    views={photos.map(x => ({
                                    ...x,
                                    srcset: x.srcSet,
                                    caption: x.title
                                }))}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                </div>
            )
        )
    }
}