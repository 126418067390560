import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';

import CssBaseline from '@material-ui/core/CssBaseline';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';


export default class SandahlContact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            subject: '',
            message: '',
        };
    }
    onSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        console.log(data);
        fetch('/submit/', {
          method: 'POST',
          body: data,
        });
    }
    render() {
        return (
            <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div >
              <Typography component="h1" variant="h5">
                If you are interested in more info or purchasing a Handmade Pipe from Jeremiah, please send a message through the <a href="https://www.facebook.com/SandahlPipe/" target="_blank"> Sandahl Pipe Facebook Page</a>
              </Typography>
            </div>
          </Container>
        )
    }
}
