import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  appBar: {
    backgroundColor: '#adb9cc'
  }
};

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

class SandahlMenu extends React.Component {
    constructor(props) {
       super(props);
       this.state = {
            value: 0,
       };
    }

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue,
        });
    }
    render() {
        const { value } = this.state;
        const { First, Second, Third, Fourth, Fifth, firstLabel, secondLabel, thirdLabel, fourthLabel, fifthLabel, classes, } = this.props
        return(
            <div>
                <AppBar position="static" className={classes.appBar}>
                  <Tabs value={value} onChange={this.handleChange}>
                    <Tab label={firstLabel} />
                    <Tab label={secondLabel} />
                    <Tab label={thirdLabel} />
                    <Tab label={fourthLabel} />
                    <Tab label={fifthLabel} />
                  </Tabs>
                </AppBar>
                {value === 0 && <TabContainer>{First}</TabContainer>}
                {value === 1 && <TabContainer>{Second}</TabContainer>}
                {value === 2 && <TabContainer>{Third}</TabContainer>}
                {value === 3 && <TabContainer>{Fourth}</TabContainer>}
                {value === 4 && <TabContainer>{Fifth}</TabContainer>}
            </div>
        )
    }
}
export default withStyles(styles)(SandahlMenu);